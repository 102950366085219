<template>
  <a-layout>
    <a-layout-header style="background: #fff; padding: 0"/>
    <a-layout-content style="margin: 0 16px">
      <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>首页轮播列表</a-breadcrumb-item>
      </a-breadcrumb>
      <!--            <div class="toolsbar">-->
      <!--                <div style="float: left;margin-left: 20px;margin-top: 10px;">-->
      <!--                    <a-button type="primary" @click="addadmin()">新建管理员</a-button>-->
      <!--                    <a-button type="primary" :loading="loading" @click="start" style="margin-left: 20px">-->
      <!--                        刷新-->
      <!--                    </a-button>-->
      <!--                </div>-->
      <!--                <div class="serach_input">-->
      <!--                    <a-input-group compact>-->
      <!--                        <a-select defaultValue="用户名">-->
      <!--                            <a-select-option value="username">用户名</a-select-option>-->
      <!--                            &lt;!&ndash;                    <a-select-option value="username">用户名</a-select-option>&ndash;&gt;-->
      <!--                        </a-select>-->
      <!--                        <a-input-search style="width: 64%" defaultValue @search="onSearch" enterButton/>-->
      <!--                    </a-input-group>-->
      <!--                </div>-->
      <!--            </div>-->
      <a-row>
        <a-col :push="5" :span="19">
          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-result title="没有数据" v-if="this.Carouseldata.length === 0">

            </a-result>
            <a-row justify="space-between" type="flex"  v-if="this.Carouseldata.length > 0">
              <a-col :span="4">
              </a-col>
              <a-col :span="12" style="text-align:right;margin-bottom:10px">
                <a-button style="margin-right: 10px" type="primary" @click="editCarouse(Carouse_id)">
                  编辑
                </a-button>
                <a-button style="margin-right: 0px" type="primary" @click="delCarouse(Carouse_id)">
                  剔除
                </a-button>

              </a-col>

            </a-row>
            <a-carousel :after-change="onChange"  ref="varousel">
<!--              :style="{'background-image': `url(${require('')})`}"-->
              <div  :class="'carousel_'+index" v-for="(item,index) in Carouseldata" :key="index" :style="{backgroundImage: 'url('+server_url+item.cover_max_img+')'}">

                <div class="carousel_info">
                  <h3>{{ item.title}}</h3>
                  <h4>{{ item.title2}}</h4>
                  <a-button class="btn-danger" shape="round" size="large" type="danger">
                    了解更多
                    <a-icon type="arrow-right"/>
                  </a-button>
                </div>
                </div>

            </a-carousel>
          </div>
        </a-col>
        <a-col :pull="19" :span="5">

          <div :style="{ padding: '24px', background: '#fff', minHeight: '650px' }">
            <a-radio-group v-model="language" button-style="solid" style="width: 100%;margin-bottom: 10px"  @change="Changecarousellanguage" >
              <a-radio-button value="cn" style="width: 33.3%;text-align:center;">
                cn
              </a-radio-button>
              <a-radio-button value="en" style="width: 33.3%;text-align:center;">
                en
              </a-radio-button>
              <a-radio-button value="fr" style="width: 33.3%;text-align:center;">
                fr
              </a-radio-button>
            </a-radio-group>

            <a-button block type="primary" @click="addCarouse()" style="margin-bottom: 10px">
              新建
            </a-button>
            <a-list bordered :data-source="Carouseldata" class="Carouselitem">
              <a-list-item slot="renderItem" slot-scope="item, index" @click="goTo(index,item)" v-bind:class="{ active:index===current}">
                [{{ item.rank }}]
                {{ item.title }}
              </a-list-item>
            </a-list>
          </div>
        </a-col>
      </a-row>

    </a-layout-content>
    <a-layout-footer style="text-align: center"></a-layout-footer>
    <a-modal v-model="pop_Carouseadd" title="新增" width="45%" @ok="Carouse_addok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="语言">
        <a-radio-group v-model="add_Carouse.language" button-style="solid" @change="Changelanguage">
          <a-radio-button value="cn">
            Cn
          </a-radio-button>
          <a-radio-button value="en">
            En
          </a-radio-button>
          <a-radio-button value="fr">
            Fr
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="主标题">
        <a-input v-model="add_Carouse.title"/>
      </a-form-item> 
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="副标题">
        <a-input v-model="add_Carouse.title2"/>
      </a-form-item> 
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
        <a-input v-model="add_Carouse.rank"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/images'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
        <div class="note">
          * 图片尺寸:1920*560<br/>
          * 图片大小不超过2M<br/>
          * 图片类型：jpg或png<br/>
        </div>
      </a-form-item>

    </a-modal>
    <a-modal v-model="pop_Carouseedit" title="编辑" width="45%" @ok="Carouse_editok">
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="语言">
        <a-radio-group v-model="edit_Carouse.language" button-style="solid" @change="Changelanguage">
          <a-radio-button value="cn">
            Cn
          </a-radio-button>
          <a-radio-button value="en">
            En
          </a-radio-button>
          <a-radio-button value="fr">
            Fr
          </a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="主标题">
        <a-input v-model="edit_Carouse.title"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="副标题">
        <a-input v-model="edit_Carouse.title2"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
        <a-input v-model="edit_Carouse.rank"/>
      </a-form-item>
      <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" label="排序">
        <a-upload
            :action="server_url+'/api/admin/tools/saveFile?uid='+uid+'&token='+token+'&path=/images'"
            :file-list="fileList"
            class="ant-uploads"
            list-type="picture-card"
            @change="handleChange"
            @preview="handlePreview"
        >
          <div v-if="fileList.length < 1">
            <a-icon type="plus"/>
            <div class="ant-upload-text">
              upload
            </div>
          </div>
        </a-upload>
        <div class="note">
          * 图片尺寸:1920*560<br/>
          * 图片大小不超过2M<br/>
          * 图片类型：jpg或png<br/>
        </div>
      </a-form-item>

    </a-modal>

  </a-layout>

</template>

<script>
// import moment from "moment";

import Config from "@/config";

const columns = [
  {
    title: "id",
    dataIndex: "uid",
    width: "5%",
    scopedSlots: {customRender: "uid"}
  },
  {
    title: "用户名",
    dataIndex: "login_name",
    width: "15%",
    scopedSlots: {customRender: "login_name"}
  },
  {
    title: "名字",
    dataIndex: "name",
    width: "15%",
    scopedSlots: {customRender: "name"}
  },

  {
    title: "角色",
    dataIndex: "tags",
    scopedSlots: {customRender: "tags"}
  },
  {
    title: "最后登录时间",
    dataIndex: "updated_at",
    width: "15%",
    scopedSlots: {customRender: "updated_at"}
  },

  // {
  //   title: "状态",
  //   width: "5%",
  //   dataIndex: "status",
  //   scopedSlots: {customRender: "status"}
  // },
  {
    title: '操作',
    width: "10%",
    key: 'operation',
    scopedSlots: {customRender: 'operation'}
  },
];

import {Modal} from "ant-design-vue";
import Storage from "../common/storage";

// import * as Util from "../common/util";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      columns,
      current:0,
      language:"cn",
      selectedKeys: ['1'],
      expandedKeys: ['1'],
      expandedaddKeys: ['1'],
      data: [],
      Carouseldata: [],
      CarouseuserData: [],
      pagination: {
        pageSize: 10
      },
      Carouse_id: "",
      Carouse_pid: "",
      Carouse_name: "",
      Carouse_show: false,
      loading: false,
      pop_Carouseadd: false,
      pop_Carouseedit: false,
      pop_Carousechildrenadd: false,
      pop_Useradd: false,
      pop_Useredit: false,
      uid: Storage.uid,
      token: Storage.token,
      server_url: Config.data.server_url,
      previewVisible: false,
      fileList: [],
      previewImage: '',
      cover_img: '',
      add_Carouse: {
        title: "",
        title2: "",
        content: "",
        type: "",
        rank: "",
        jump_url: "",
        cover_max_img: "",
        cover_min_img: "",
        language: "cn",
      },
      edit_Carouse: {
        title: "",
        title2: "",
        content: "",
        type: "",
        rank: "",
        jump_url: "",
        cover_max_img: "",
        cover_min_img: "",
        language: "cn",
      },

    };
  },
  created() {
    // 获取第一页内容
    this.getCarousellist(1, 20,"",this.language);

    // Promise.all([this.getitem(), this.getresource()]).then(() => {
    //   this.loading = false;
    // }).catch((error) => {
    //   console.log(error)
    // })
  },
  methods: {
    goTo(index,item){
      this.$refs.varousel.goTo(index,false);
      this.current=index;
      this.edit_Carouse=item
      console.log(item)
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
      this.add_Carouse.cover_max_img = "";
      console.log("info", info);
      console.log(info.file.status);
      this.fileList = info.fileList;
      for (var i = 0; i < this.fileList.length; i++) {
        if (info.file.status === "done" || info.file.status === "removed") {
          this.add_Carouse.cover_max_img = this.fileList[i].response.src;
          this.edit_Carouse.cover_max_img = this.fileList[i].response.src;
          // this.add_Carouse.cover_img = this.fileList[i].response.src;
        }
        // console.log(info.fileList[i]);
        // console.log(info.fileList[i].response.src);
      }
      console.log(this.add_Carouse.cover_max_img);
      console.log(this.edit_Carouse.cover_max_img);
    },
    start() {
      this.loading = true;
      this.data = [];
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
      this.doSearch(1, this.pagination.pageSize);
    },

    async onSearch(text) {
      this.data = []
      this.search_text = text;
      this.doSearch(1, this.pagination.pageSize, text);
    },

    async onChange() {
      // await this.doSearch(
      //     this.search_text,
      //     pagination.current - 1,
      //     pagination.pageSize
      // );
    },

    async onChanges(e) {
      console.log(e.target.value);
    },

    addCarouse() {
      console.log("点击");
      this.fileList=[];
      if(this.Carouseldata.length> 9){
        Modal.warning({
          title: "警告！",
          content: "建议当前首页轮播不超过10个",
        })
        return;
      }
      this.pop_Carouseadd = true;
    },
    editCarouse() {
      console.log("点击",this.edit_Carouse);

      this.fileList=[];
      this.fileList=[{
        uid: 0,
        name: 'name',
        status: 'done',
        url: this.server_url + this.edit_Carouse.cover_max_img,
        response: {
          data: this.edit_Carouse.cover_max_img
          // , JSON.parse(result.data.more_info_json)[k]
        }
      }]
      this.pop_Carouseedit = true;
    },

    async Carouse_addok() {
      if (this.add_Carouse.title === "") {
        Modal.warning({
          title: "请输入标题"
        });
        this.pop_Carouseadd = true;
        return
      }

      let result = await this.$post("/api/admin/systemIndexMoreInfos/create", {
        uid: Storage.uid,
        token: Storage.token,
        save_value: {
          title: this.add_Carouse.title,
          title2: this.add_Carouse.title2,
          content: this.add_Carouse.content,
          type: this.add_Carouse.type,
          rank: this.add_Carouse.rank,
          jump_url: this.add_Carouse.jump_url,
          cover_max_img:this.add_Carouse.cover_max_img,
          cover_min_img: this.add_Carouse.cover_min_img,
          language: this.add_Carouse.language,
        },

      });

      if (result.status === true) {
        this.data = []
        await this.getCarousellist(1, 20,"",this.language);
        Modal.info({
          title: "更新成功"
        });
      }
      this.pop_Carouseadd = false;
    },



    async getCarousellist(page, limit, type,language) {
      console.log(language);
      this.Carouseldata =[]
      this.loading = true;
      let result = await this.$get("/api/admin/systemIndexMoreInfos/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          page: page,
          limit: limit,
          type: type,
          language: language,
        }
      });

      if (result.status === true) {
        console.log(result.data)

        this.Carouseldata =  result.data.list;
        if(this.Carouseldata.length > 0){
          this.goTo(0,this.Carouseldata[0])
        }


        this.loading = false;
      }

      this.loading = false;
    },

    Changelanguage(e) {
      console.log(e.target.value)
      this.add_Carouse.language = e.target.value
      console.log(this.add_Carouse.language);
    },
    Changecarousellanguage(e) {
      console.log(e.target.value);
      this.language = e.target.value
      this.getCarousellist(1, 20,"",this.language);
    },

    async Carouse_editok() {
      if (this.edit_Carouse.name === "") {
        Modal.warning({
          title: "请输入部门名字"
        });
        return;
      }


      let result = await this.$post("/api/admin/systemIndexMoreInfos/update", {
        uid: Storage.uid,
        token: Storage.token,
        id: this.edit_Carouse.id,
        save_value: {
          title: this.edit_Carouse.title,
          title2: this.edit_Carouse.title2,
          content: this.edit_Carouse.content,
          type: this.edit_Carouse.type,
          rank: this.edit_Carouse.rank,
          jump_url: this.edit_Carouse.jump_url,
          cover_max_img:this.edit_Carouse.cover_max_img,
          cover_min_img: this.edit_Carouse.cover_min_img,
          language: this.edit_Carouse.language,
        },
      });

      if (result.status === true) {
        this.data = []
        await this.getCarousellist(1, 20,"",this.language);
        Modal.info({
          title: "修改成功"
        });
      }
      this.Carouse_name = this.edit_Carouse.name;
      this.pop_Carouseedit = false;
    },


    async delCarouse() {
      var that = this;

      console.log(this.edit_Carouse.id);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除?",

        onOk: async function () {

          let result = await that.$post("/api/admin/systemIndexMoreInfos/delete", {

            uid: Storage.uid,
            token: Storage.token,
            id: that.edit_Carouse.id,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await  that.getCarousellist(1, 20,"",that.language);

            that.Carouse_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });

          }
        }

      });


    },
    async deladmin(item) {
      var that = this;
      console.log(item);
      Modal.confirm({
        title: "警告！",
        content: "是否确认剔除该部门?",

        onOk: async function () {

          let result = await that.$post("/api/admin/account/delete", {

            uid: Storage.uid,
            token: Storage.token,
            edit_uid: item.uid,
            // notes: this.edit_pop.notes,
            t: Date.now(),
            // sign: Crypt.sign([
            //     Storage.token,
            //     this.edit_pop.id,
            //     JSON.stringify(data),
            //     this.edit_pop.notes,
            //     this.$config.secret_key,
            //     now
            // ])
          });
          console.log(result);
          if (result.status === true) {
            console.log("准备弹窗！");
            await that.getCarousellist(1, 20,"",this.language);
            that.Carouse_show = false;
            Modal.info({
              title: "剔除成功"
            });
          }
          if (result.status === false) {
            // that.data = []
            // await this.doSearch(this.page, "1", "", this.estatedefault, "");
            Modal.info({
              title: result.msg
            });
          }
        }

      });


    },
  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff
}
.carousel_info {
  width: 1248px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 281px;

}
.carousel_info h3 {
  color: #fff;
  font-size: 38px;
  font-weight: bold;
}

.carousel_info h4 {
  color: #fff;
  font-size: 19px;
  margin-bottom: 20px;
}



.carousel_0,.carousel_1,.carousel_2,.carousel_3,.carousel_4,.carousel_5.carousel_6,.carousel_7,.carousel_8,.carousel_9,.carousel_10{
  background-color: #1890ff;
  max-width: 1920px !important;
  height: 680px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top

}
.Carouselitem{
  cursor: pointer;
}
.Carouselitem .active{
  background-color: #1890ff;
  color: #fff;
}
.Carouselitem li:focus{
  background-color: #1890ff;
  color: #fff;
}
.note{
  line-height:110%;
  font-size:12px;
  color: #ccc;
}
</style>
